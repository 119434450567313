<template lang="pug">
    main
      .inside-content
        .container
          h1.page-title Блог
          .blog-grid

            article.article-card
              a.article-card__img(@click="goWhy")
                img(src="~images/blog/article-img.png" style="height: 100%")

              .blog-card__content
                a.blog-card__title(@click="goWhy") Зачем нужны расчеты
                .blog-desc
                  p Думаем, ни для кого не секрет, что подавляющее большинство частных домов строится не только без проекта, но и вообще без расчетов.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon
                      //svg(viewbox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg')
                      //  circle(cx='7' cy='7' r='6' stroke='#6E7288')
                      //  path(d='M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10' stroke='#6E7288')
                      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 10 минут

                  a.blog-card__read-more(@click="goWhy")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goHeatloss")
                img(src="~images/blog/house.svg" style="padding: 20%")

              .blog-card__content
                a.blog-card__title(@click="goHeatloss") Расчет теплопотерь
                div.blog-desc
                  p Наш онлайн калькулятор теплопотерь позволяет сделать расчет в двух вариантах: Упрощенный или Подробный.
                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 3 минуты

                  a.blog-card__read-more(@click="goHeatloss")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goFloor")
                img(src="~images/blog/warm-floor.svg" style="padding: 25%")
                //include ../assets/images/blog/warm-floor.svg
              .blog-card__content
                a.blog-card__title(@click="goFloor") Расчет теплого пола
                .blog-desc
                  p Водяной теплый пол — это залитые в бетон трубы. Если что-то сделано неправильно, устранение ошибки обойдется очень дорого.
                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 5 минут

                  a.blog-card__read-more(@click="goFloor")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goRadiator")
                img(src="~images/blog/radiator.svg" style="padding: 25%")
                //include ../assets/images/blog/radiator.svg
              .blog-card__content
                a.blog-card__title(@click="goRadiator") Расчет радиаторов
                .blog-desc
                  p В советское время выбор радиатора (прибора отопления) был предопределен. Вот вам чугунная батарея – грейтесь.
                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 4 минуты

                  a.blog-card__read-more(@click="goRadiator")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

</template>

<script>
import navigationMixin from '../mixins/navigation'

export default {
  name: 'Blog',
  mixins: [navigationMixin],
  metaInfo: {
    title: 'Статьи про расчеты отопления частного дома',
    meta: [
      {
        name: 'description',
        content: 'Из статей вы узнаете как расчет отопления помогает не допустить ошибок и сэкономить на оборудовании.'
      }
    ]
  }
}
</script>
